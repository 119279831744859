import { useCms } from '../UseCms'

export function useSubMenu() {

    const { services } = useCms()

    const items = services.value?.map(service => ({

        title: service.title, slug: service.slug

    }))

    return {
        services: items
    }

}
