import { Email } from './rules/Email'
import { Required } from './rules/Required'
import { Rule } from './rules/Rule'

export const req = (value: any) => {

    if (Array.isArray(value)) {

        return !!value.length

    }

    if (value === undefined || value === null) {

        return false

    }

    if (value === false) {

        return true

    }

    if (value instanceof Date) {

        // Invalid date won't pass
        return !isNaN(value.getTime())

    }

    if (typeof value === 'object') {

        let hasKeys = false

        for (const _ in value) {

            hasKeys = true

        }

        return hasKeys

    }

    return !!String(value).length

}

export function isBlank(value: string | number | null): boolean {

    return value === '' || value === null

}

export function required(errorMessage: string | null = null): Rule {

    return new Required(errorMessage)

}

export function email(errorMessage: string | null = null): Rule {

    return new Email(errorMessage)

}
