import { Route } from '../enums/Route'

let previousPageName = ''

export function checkIsSeamlessPageTransition(to: any, from: any, record = false) {

    if (to.name === from.name && to.name === Route.Blog && from.query.category !== to.query.category) {

        return true

    }

    const isSeamlessPageTransition = to.params.isSeamless === 'true' || (from.params.isSeamless === 'true' && to.name === previousPageName)

    if (record) {

        previousPageName = from.name

    }

    return isSeamlessPageTransition

}
