

    import { useStickyMenu } from '../compositions/UseStickyMenu'
    import { useSubMenu } from '../compositions/menu/UseSubMenu'
    import { defineComponent } from 'vue'
    import { interactWithMenu } from '../compositions/menu/InteractWithMenu'
    import { useMobileMenu } from '../compositions/menu/UseMobileMenu'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Locale } from '../enums/Locale'
    import { Route } from '../enums/Route'
    import Chinese from './Icons/Chinese.vue'
    import Hamburger from './Icons/Hamburger.vue'
    import DC from './Logos/DC.vue'
    import SocialMediaButton from './SocialMediaButton.vue'
    import { useCms } from '../compositions/UseCms'
    import { Region } from '../enums/Region'
    import { useRegion } from '../compositions/UseRegion'
    import { isCNLocale } from '../compositions/useI18n'
    import { useRoute } from 'vue-router'

    export default defineComponent({
        name: 'Menu',
        components: {
            DC,
            Hamburger,
            Chinese,
            SocialMediaButton
        // Paragraph
        },
        setup() {

            const { modeClass } = interactWithMenu()
            const { show, toggle, isFluid } = useMobileMenu()

            const {
                menuStyle,
                menuRef,
                menuClasses,
                clearMenuBackground,
                hideMenu,
                showMenu,
                isMenuHidden
            } = useStickyMenu()

            const { services } = useSubMenu()
            const { region } = useRegion()
            const { menu } = useTranslation()

            const route = useRoute()

            function switchLocale() {

                if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_LANGUAGE) {

                    const { path } = route

                    window.location.href = isCNLocale.value ? `https://digitalcreative.cn${ path }` : `https://zh.digitalcreative.cn${ path }`

                } else {

                    console.warn("Language switch button won't work in dev, change command parameters to run the project instead.")

                }

            }

            return {
                Route,
                Locale,
                Region,
                region,
                modeClass,
                toggle,
                show,
                menuStyle,
                menuRef,
                menuClasses,
                data: menu,
                isFluid,
                switchLocale,
                isCNLocale,
                clearMenuBackground,
                hideMenu,
                showMenu,
                services,
                isMenuHidden,
                socialMedia: [
                    {
                        name: 'LinkedIn',
                        color: '#4285EB',
                        link: 'https://linkedin.com/company/digital-creative-asia/'
                    },
                    {
                        name: 'Instagram',
                        color: '#F2994A',
                        link: 'https://instagram.com/dc.asia'
                    }
                ]
            }

        }
    })

