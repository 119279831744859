import { ref } from 'vue'
import { Theme } from '../enums/Theme'
import { useRoute } from 'vue-router'
import { themeColor } from '../themeColor'

const currentTheme = ref<Theme>(Theme.Default)
const currentColor = ref<string>('')

function setBackground(color: string) {

    document.body.style.backgroundColor = color
    currentColor.value = color

}

function setTheme(theme: Theme, withTransition = true) {

    document.body.setAttribute('class', `theme --${ theme } ${ withTransition ? '--with-transition' : '' }`)

}

export function useTheme() {

    return {
        currentTheme,
        currentColor,
        setTheme,
        setBackground,
        initializeCurrentThemeBasedOnRouteMeta(withTransition = false) {

            const { meta } = useRoute()
            const theme: Theme = (meta.theme as Theme) ?? Theme.Default

            setTheme(theme, withTransition)
            setBackground(themeColor[theme] ?? Theme.Default)

        }
    }

}
