

    import { defineComponent, onMounted, provide, readonly, ref } from 'vue'
    import { useRouter } from 'vue-router'
    import Debugger from './components/Debugger.vue'
    import Footer from './components/Footer.vue'
    import Menu from './components/Menu.vue'
    import { seamlessPageTransitionFlag } from './compositions/App'
    import { checkIsSeamlessPageTransition } from './compositions/checkIsSeamlessPageTransition'
    import { useProvideTeleportFunctionality } from './compositions/UseCaseHeader'
    import { Theme } from './enums/Theme'
    import { VueInstance } from './Interfaces/VueInstance'
    import { themeColor } from './themeColor'
    import { useTranslation } from './compositions/UseTranslation'
    import { Locale } from './enums/Locale'
    import { isBrowserMode } from './utils'
    import ThemeChanger from './components/ThemeChanger.vue'

    export default defineComponent({
        name: 'App',
        components: {
            Footer,
            Menu,
            Debugger,
            ThemeChanger
        },
        setup() {

            const router = useRouter()
            const { currentLocale } = useTranslation()
            const { headers } = useProvideTeleportFunctionality()

            const isSeamlessPageTransition = ref<boolean>(false)

            provide(seamlessPageTransitionFlag, readonly(isSeamlessPageTransition))

            onMounted(() => {

                setTimeout(() => {

                    router.beforeEach((to, from) => {

                        isSeamlessPageTransition.value = checkIsSeamlessPageTransition(to, from, true)

                    })

                })

            })

            return {
                isBrowserMode,
                Locale,
                currentLocale,
                headers,
                isSeamlessPageTransition,
                Theme
            }

        },
        data() {

            return {
                isPageCurtainShowing: false,
                next: null as any,
                curtainColor: '',
                hasRouterViewMounted: false
            }

        },
        mounted() {

            setTimeout(() => {

                this.$router.beforeEach((to, from, next) => {

                    if (to.hash) {

                        next()

                    }

                    if (!this.isSeamlessPageTransition) {

                        this.curtainColor = themeColor[to.meta?.theme as Theme] || themeColor[Theme.Default]

                        if (Object.prototype.hasOwnProperty.call(to.params, 'gradient')) {

                            this.curtainColor = to.params.gradient

                        }

                        this.isPageCurtainShowing = true
                        this.next = next

                    } else {

                        next()

                    }

                })

            })

        },
        created() {

            this.$router.afterEach(to => {

                if (!this.hasRouterViewMounted) {

                    const initAOSEvent = new Event('init-aos')

                    this.hasRouterViewMounted = true

                    this.$nextTick(() => {

                        document.dispatchEvent(initAOSEvent)

                    })

                }

                if (!this.isSeamlessPageTransition) {

                    setTimeout(() => {

                        this.isPageCurtainShowing = false

                    })

                }

                setTimeout(() => {

                    (this as any).$aos.refreshHard()

                })

            })

        },
        methods: {
            duringCurtainEntering() {

                setTimeout(() => (this.$refs.menu as VueInstance)?.clearMenuBackground(), 400)

            },
            afterCurtainEnter() {

                this.next && this.next()
                this.next = null

            },
            hideMenu() {

                (this.$refs.menu as VueInstance)?.hideMenu()

            },
            showMenu() {

                (this.$refs.menu as VueInstance)?.showMenu()

            }
        }
    })

