import { markRaw, reactive } from 'vue'
import { Theme } from '../enums/Theme'

export interface HeaderDefinition {
    header: any,
    target?: string,
    key: string,
    theme?: Theme,
    props?: Record<string, any>,
}

const headers: Record<string, HeaderDefinition> = reactive({})

let id = 0

export const useCaseHeader = () => ({
    generateTargetId(): string {

        return `target-${ id++ }`

    },
    updateHeader({ header, key, props: properties = {}, target }: HeaderDefinition) {

        if (headers[key]?.target === target) {

            throw new Error('Please update the target, it can not be duplicated.')

        }

        headers[key] = reactive({
            key,
            target,
            props: properties,
            header: markRaw(header)
        })

    }

})

export const useProvideTeleportFunctionality = () => ({
    headers
})
