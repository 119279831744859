import { Rule } from './Rule'
import { isBlank } from '../UseValidators'

export class Email extends Rule {

    public validate(value: string | number | null): boolean {

        if (isBlank(value)) {

            return false

        }

        const emailRegex = /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/

        return emailRegex.test(value!.toString())

    }

}
