import { ref } from 'vue'

export const showGrid = ref(false)

export function useGrid() {

    return {
        showGrid,
        toggleGrid() {

            showGrid.value = !showGrid.value

        }
    }

}
