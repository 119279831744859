import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type"]
const _hoisted_3 = ["id", "type"]
const _hoisted_4 = {
  key: 0,
  class: "mx-5 my-2 text-xs text-gray-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Error = _resolveComponent("Error")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input relative", { '--invalid': _ctx.hasErrors }])
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(["input__label", [ `--${ _ctx.type }` ]]),
      for: _ctx.name
    }, [
      (_ctx.type === _ctx.InputType.TextArea)
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 0,
            id: _ctx.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            type: _ctx.fieldType,
            rows: "8",
            class: _normalizeClass(["input__textarea", { '--active': _ctx.value, '--extra-padding': _ctx.extraPadding }])
          }, null, 10, _hoisted_2)), [
            [_vModelText, _ctx.value]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 1,
            id: _ctx.name,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value) = $event)),
            type: _ctx.fieldType,
            class: _normalizeClass({ '--active': _ctx.value })
          }, null, 10, _hoisted_3)), [
            [_vModelDynamic, _ctx.value]
          ]),
      (_ctx.type !== _ctx.InputType.Checkbox)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["input__placeholder", { '--extra-padding': _ctx.extraPadding }])
          }, _toDisplayString(_ctx.placeholder), 3))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    _createVNode(_component_Error, { class: "input__error absolute" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.errorMessages[ 0 ]), 1)
      ]),
      _: 1
    }),
    (_ctx.help && !_ctx.hasErrors)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.help), 1))
      : _createCommentVNode("", true)
  ], 2))
}