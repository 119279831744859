import { req as request } from '../UseValidators'
import { Rule } from './Rule'

export class Required extends Rule {

    public validate(value: string | number | null): boolean {

        if (typeof value === 'string') {

            return request(value.trim())

        }

        return request(value)

    }

}
