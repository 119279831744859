import { useRouter } from 'vue-router'
import { currentActiveBreakPoint } from '../UseResponsiveValue'
import { Breakpoint } from '../../enums/Breakpoint'
import { onUnmounted, ref, watchEffect } from 'vue'
import { interactWithMenu, Mode } from './InteractWithMenu'

const show = ref(false)
const isFluid = ref(false)

export function useMobileMenu() {

    const { setMode, mode } = interactWithMenu()
    const router = useRouter()

    let previousMode = mode.value

    /**
     * Auto close menu if window is resized away from the mobile breakpoint
     */
    function closeMenuOnWindowsResize() {

        if (currentActiveBreakPoint.value !== Breakpoint.SM) {

            toggle(false)

        }

    }

    window.addEventListener('resize', closeMenuOnWindowsResize)
    onUnmounted(() => window.removeEventListener('resize', closeMenuOnWindowsResize))

    /**
     * Close menu before route leave
     */
    router.afterEach(() => toggle(show.value = false))

    watchEffect(() => {

        if (!show.value) {

            previousMode = mode.value

        }

        /**
         * Make menu responsive when it is on desktop and fixed on mobile
         */
        isFluid.value = currentActiveBreakPoint.value !== Breakpoint.SM

    })

    function toggle(value: boolean) {

        show.value = value

        if (value) {

            document.documentElement.style.setProperty('overflow', 'hidden')
            setMode(Mode.LightNoTransition)

        } else {

            document.documentElement.style.removeProperty('overflow')
            setMode(previousMode)

        }

    }

    return {
        show,
        toggle,
        isFluid
    }

}
