

    import Anchor from './Anchor.vue'
    import Instagram from './Logos/Social/Instagram.vue'
    import LinkedIn from './Logos/Social/LinkedIn.vue'
    import YouTube from './Logos/Social/YouTube.vue'

    export default {
        name: 'SocialMediaButton',
        components: {
            Instagram, LinkedIn, YouTube, Anchor
        },
        props: {
            name: { type: String, required: true },
            link: { type: String, required: true },
            large: { type: Boolean, default: false }
        }
    }

