

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Error',
        props: {
            center: { type: Boolean, default: false }
        }
    })

