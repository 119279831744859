export abstract class Rule {

    public state = true

    // public constructor(public errorMessage: string | null = null) {

    // }

    abstract validate(value: string | number | null): boolean

    public process(value: string | number | null): boolean {

        this.state = this.validate(value)

        return this.state

    }

    public reset(): void {

        this.state = true

    }

}
