

    import { defineComponent, h, onMounted, PropType, ref, watchEffect } from 'vue'
    import { useComponentIsOnScreen } from '../compositions/behaviors/UseComponentIsOnScreen'
    import { useTheme } from '../compositions/UseTheme'
    import { Theme } from '../enums/Theme'
    import { themeColor } from '../themeColor'

    export default defineComponent({
        name: 'ThemeChanger',
        props: {
            to: { type: String as PropType<Theme>, default: Theme.Default },
            background: { type: String, required: false }
        },
        setup(props, { slots }) {

            const { setTheme, setBackground } = useTheme()
            const componentReference = ref<HTMLElement | ReturnType<typeof defineComponent>>()

            onMounted(() => {

                const { activeElementIndex } = useComponentIsOnScreen([ componentReference.value as HTMLElement ], 35)

                watchEffect(() => {

                    if (activeElementIndex.value >= 0) {

                        setTheme(props.to)
                        setBackground(props.background ?? themeColor[props.to])

                    }

                })

            })

            return () => h('div', { ref: componentReference }, slots)

        }
    })

