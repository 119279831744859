import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import { Locale } from '../enums/Locale'
import homeEn from '../data/en/home.json'
import homeCn from '../data/cn/home.json'
import aboutEn from '../data/en/about.json'
import aboutCn from '../data/cn/about.json'
import whatWeDoEn from '../data/en/what-we-do.json'
import whatWeDoCn from '../data/cn/what-we-do.json'
import workEn from '../data/en/work.json'
import workCn from '../data/cn/work.json'
import openSourceEn from '../data/en/open-source.json'
import openSourceCn from '../data/cn/open-source.json'
import contactEn from '../data/en/contact.json'
import contactCn from '../data/cn/contact.json'
import menuEn from '../data/en/menu.json'
import menuCn from '../data/cn/menu.json'
import menuHKEN from '../data/hk_en/menu.json'
import footerEn from '../data/en/footer.json'
import footerCn from '../data/cn/footer.json'
import casesEn from '../data/en/cases.json'
import casesCn from '../data/cn/cases.json'
import cursorEn from '../data/en/cursor.json'
import cursorCn from '../data/cn/cursor.json'
import caseStudiesCn from '../data/cn/cases-studies.json'
import caseStudiesEn from '../data/en/cases-studies.json'
import notFoundEn from '../data/en/not-found.json'
import notFoundCn from '../data/cn/not-found.json'
import { useRegion } from './UseRegion'
import { Region } from '../enums/Region'

const isCNLocale = ref(process.env.VUE_APP_LANGUAGE === Locale.CHINESE)
const { region } = useRegion()

export function useTranslation() {

    const route = useRoute()

    function switchLocale() {

        if (process.env.VUE_APP_ENVIRONEMENT === 'production') {

            const { path } = route

            window.location.href = isCNLocale.value ? `https://digitalcreative.cn${ path }` : `https://zh.digitalcreative.cn${ path }`

        } else if (process.env.VUE_APP_ENVIRONEMENT === 'staging') {

            const { path } = route

            window.location.href = isCNLocale.value ? `https://staging.digitalcreative.cn${ path }` : `https://staging.zh.digitalcreative.cn${ path }`

        } else {

            isCNLocale.value = !isCNLocale.value

        }

    }

    return {
        isCNLocale,
        switchLocale,
        currentLocale: computed(() => isCNLocale.value ? Locale.CHINESE : Locale.ENGLISH),

        /**
         * Pages
         */
        home: computed(() => (isCNLocale.value ? homeCn : homeEn)),
        about: computed(() => (isCNLocale.value ? aboutCn : aboutEn)),
        whatWeDo: computed(() => (isCNLocale.value ? whatWeDoCn : whatWeDoEn)),
        work: computed(() => (isCNLocale.value ? workCn : workEn)),
        openSource: computed(() => isCNLocale.value ? openSourceCn : openSourceEn),
        contact: computed(() => (isCNLocale.value ? contactCn : contactEn)),
        menu: computed(() => (region === Region.HK
            ? menuHKEN
            : isCNLocale.value ? menuCn : menuEn)),
        footer: computed(() => (isCNLocale.value ? footerCn : footerEn)),
        cursor: computed(() => (isCNLocale.value ? cursorCn : cursorEn)),
        caseStudies: computed(() => (isCNLocale.value ? caseStudiesCn : caseStudiesEn)),
        notFound: computed(() => (isCNLocale.value ? notFoundCn : notFoundEn)),

        /**
         * Cases
         */
        hublotEC: computed(() => isCNLocale.value ? casesCn['hublot-ec'] : casesEn['hublot-ec']),
        hublotWCT: computed(() => isCNLocale.value ? casesCn['hublot-wct'] : casesEn['hublot-wct']),
        nike: computed(() => (isCNLocale.value ? casesCn.nike : casesEn.nike))
    }

}
