import { Rule } from './Rule'
import { Rules, RulesOptions } from './Rules'

export class ValidationManager {

    private collection: Rules[] = []

    public constructor(private options: Partial<RulesOptions>) {

        console.log('')

    }

    public validate(): boolean {

        return this.collection.map(item => item.validate(true)).every(Boolean)

    }

    public createRulePack(name: string, rules: Rule[]): Rules {

        const rulePack = new Rules(name, rules, this.options)

        this.collection.push(rulePack)

        return rulePack

    }

    public getData() {

        const finalData: Record<string, string | number | null> = {}

        for (const item of this.collection) {

            finalData[item.name] = item.value

        }

        return finalData

    }

}
