import { computed, ComputedRef, ref, Ref, shallowRef } from 'vue'
import { Breakpoint } from '../enums/Breakpoint'

export type NormalizedValue<T> = T[]

export type ResponsiveValue<T> = T | NormalizedValue<T>

export const currentActiveBreakPoint = ref(Breakpoint.SM)

/**
 * Note: max-width must always be the first param defined
 */
const breakpoints: Record<Breakpoint, string> = {
    [Breakpoint.SM]: '(max-width: 767px)',
    [Breakpoint.MD]: '(max-width: 1023px) and (min-width: 768px)',
    [Breakpoint.LG]: '(max-width: 1439px) and (min-width: 1024px)',
    [Breakpoint.XL]: '(min-width: 1440px)'
}

/**
 * Map values to breakpoints { breakpoint: value }
 */
function mapValuesToBreakPoint<T>(values: NormalizedValue<T>): Record<Breakpoint, T> {

    return Object.values(Breakpoint)
        .map((breakpoint, index) => ({ [breakpoint]: values[index] !== undefined ? values[index] : values[values.length - 1] }))
        .reduce((a, b) => ({ ...b, ...a })) as unknown as Record<Breakpoint, T>

}

function normalizeResponsiveValue<T>(value: ResponsiveValue<T>): NormalizedValue<T> {

    const normalized: NormalizedValue<T> = []

    if (Array.isArray(value)) {

        normalized.push(...value)

    } else {

        normalized.push(value)

    }

    return normalized

}

function applyMatch(event: MediaQueryListEvent | MediaQueryList) {

    if (event.matches) {

        for (const breakpoint in breakpoints) {

            if (breakpoints[breakpoint as Breakpoint] === event.media) {

                currentActiveBreakPoint.value = breakpoint as Breakpoint

                break

            }

        }

    }

}

for (const breakpoint in breakpoints) {

    const media = window.matchMedia(breakpoints[breakpoint as Breakpoint])

    applyMatch(media)

    if ('addEventListener' in media) {

        media.addEventListener('change', applyMatch)

    } else {

        media.addListener(applyMatch)

    }

}

export function useResponsiveValue<T>(values: ResponsiveValue<T>): ComputedRef<T> | Ref<T> {

    if (!Array.isArray(values)) {

        return shallowRef(values)

    }

    const responsiveValue = normalizeResponsiveValue(values)
    const breakpointValues = mapValuesToBreakPoint<T>(responsiveValue)

    return computed(() => breakpointValues[currentActiveBreakPoint.value])

}
