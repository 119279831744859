import { onUnmounted, ref } from 'vue'

function percentage(total: number, value: number): number {

    return total * value / 100

}

export function useComponentIsOnScreen(values: HTMLElement[], offset = 50) {

    const activeElementIndex = ref(-1)

    function onScroll() {

        const items = [ ...values ].reverse()

        for (const index in items) {

            const reference = items[index]
            const offsetTop = reference.offsetTop

            if (items.length === 1) {

                if (window.pageYOffset > (offsetTop + reference.clientHeight) - percentage(window.innerHeight, offset)) {

                    activeElementIndex.value = -1

                    return

                }

            }

            if (window.pageYOffset > offsetTop - percentage(window.innerHeight, offset)) {

                activeElementIndex.value = items.length - Number(index) - 1

                return

            }

            activeElementIndex.value = -1

        }

    }

    window.addEventListener('scroll', onScroll, false)
    onUnmounted(() => window.removeEventListener('scroll', onScroll, false))

    return {
        activeElementIndex
    }

}
