

    import { defineComponent, computed } from 'vue'

    const headings: Record<string, string> = {
        h1: 'text-3xl-mo md:text-3xl',
        h2: 'text-2xl-mo md:text-2xl',
        h3: 'text-xl'
    }

    export default defineComponent({
        name: 'Header',
        props: {
            tag: { type: String, required: true },
            size: { type: String, required: false },
            noMargin: { type: Boolean, default: false },
            lessMargin: { type: Boolean, default: false },
            purple: { type: Boolean, default: false },
            pink: { type: Boolean, default: false }
        },
        setup(props) {

            return {
                classes: computed(() => {

                    return [
                        headings[props.size ?? props.tag],
                        {
                            '--no-margin': props.noMargin,
                            '--less-margin': props.lessMargin,
                            '--purple': props.purple,
                            '--pink': props.pink
                        }
                    ]

                })
            }

        }
    })

