import { computed, ref } from 'vue'

export enum Mode {
    Dark = 'dark',
    Light = 'light',
    LightNoTransition = 'light-no-transition',
}

const modeReference = ref<Mode>(Mode.Dark)

export const interactWithMenu = () => ({
    mode: modeReference,
    modeClass: computed(() => {

        if (modeReference.value === Mode.LightNoTransition) {

            return [
                'absolute',
                'text-white',
                'z-50',
                '--light-no-transition'
            ]

        }

        if (modeReference.value === Mode.Light) {

            return [
                'absolute',
                'text-white',
                'z-50',
                '--light'
            ]

        }

        return `--${ modeReference.value } relative`

    }),
    setMode(mode: Mode) {

        modeReference.value = mode

    }
})
