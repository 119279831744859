import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72aa7717"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col items-center mt-8 md:flex-row md:space-x-6" }
const _hoisted_2 = {
  key: 0,
  class: "text-base text font-body font-book text-error md:pr-8"
}
const _hoisted_3 = { class: "px-5 py-10 border border-solid border-pure-black md:py-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_CheckboxInput = _resolveComponent("CheckboxInput")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, {
    class: "email-subscription",
    centered: ""
  }, {
    default: _withCtx(() => [
      (!_ctx.isSubscriptionSuccess)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            span: 6
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Heading, {
                tag: "h2",
                class: "sm:mt-12 md:mt-0 text-2xl-mo md:text-2xl"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '订阅我们的时事简讯服务！' : 'Join our newsletter!'), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_Paragraph, { class: "text-base text-gray-600 text font-body md:pr-8" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '我们会将有关中国及全球最新数字趋势、战略和发展的宝贵见解直接发送至您的邮箱。' : 'Get valuable insights on the latest digital trends, strategies, and developments in China and globally delivered straight to your inbox.'), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isSubscriptionSuccess)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 1,
            span: 6
          }, {
            default: _withCtx(() => [
              _createElementVNode("form", {
                onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_Input, {
                    type: _ctx.InputType.Text,
                    class: "flex-auto w-full bg-white input",
                    name: "email",
                    placeholder: "Your email address",
                    validation: _ctx.validation.email
                  }, null, 8, ["type", "validation"]),
                  _createVNode(_component_Button, {
                    pinkSolid: "",
                    class: "button",
                    isLoading: _ctx.formLoading,
                    large: ""
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '提交 →' : 'Submit →'), 1)
                    ]),
                    _: 1
                  }, 8, ["isLoading"])
                ]),
                _createVNode(_component_CheckboxInput, {
                  class: "mt-8 text-gray-200",
                  checked: _ctx.checked,
                  "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '我希望接收来自 DCA 的邮件动态并同意' : 'I want to receive regular email updates from DCA and agree to the') + " ", 1),
                    _createVNode(_component_Anchor, {
                      route: { name: _ctx.Route.PrivacyPolicy }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '隐私政策' : 'Privacy policy'), 1)
                      ]),
                      _: 1
                    }, 8, ["route"]),
                    _createTextVNode(". ")
                  ]),
                  _: 1
                }, 8, ["checked"]),
                (_ctx.isCheckBoxErrorShown)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.isCNLocale ? '请先同意我们的隐私政策' : 'You need to agree to the privacy policy first.'), 1))
                  : _createCommentVNode("", true)
              ], 32)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.isSubscriptionSuccess)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 2,
            span: 6,
            class: "mt-20 md:mt-0"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Heading, {
                  tag: "h2",
                  class: "flex justify-center"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '感谢您的订阅' : 'Thank you for subscribing!'), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Paragraph, { class: "flex justify-center text-base text-gray-600 text font-body text-book md:pr-8" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isCNLocale ? '敬请关注来自 Digital Creative 团队的精彩内容和独家更新' : 'Stay tuned for exciting content and exclusive updates from the Digital Creative team.'), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}