import { computed, ref, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import { Locale } from '../enums/Locale'
import { Region } from '../enums/Region'
import workEn from '../data/en/work.json'
import workCn from '../data/cn/work.json'
import menuEn from '../data/en/menu.json'
import menuCn from '../data/cn/menu.json'
import cursorEn from '../data/en/cursor.json'
import cursorCn from '../data/cn/cursor.json'
import notFoundEn from '../data/en/not-found.json'
import notFoundCn from '../data/cn/not-found.json'

const content = require.context(`../cms/${ VUE_APP_REGION }/${ VUE_APP_LANGUAGE }`, true, VUE_APP_CONTENT_FILE)

const isCNLocale = ref(process.env.VUE_APP_LANGUAGE === Locale.CHINESE)

export function useCms() {

    let home,
        blogs,
        blog,
        about,
        openSource,
        whatWeDo,
        newContact,
        contact,
        services,
        caseStudies,
        contactForm,
        footer,
        agileTeams

    if (VUE_APP_REGION === Region.CN) {

        home = content('./pages/home.json')
        blogs = content('./blogs.json')
        blog = content('./pages/blog.json')
        about = content('./pages/about.json')
        openSource = content('./pages/open-source.json')
        whatWeDo = content('./pages/what-we-do.json')
        newContact = content('./pages/contact-us.json')
        contact = content('./pages/contact-us.json')
        services = content('./services.json')
        caseStudies = content('./cases.json')
        contactForm = content(`./forms/${ isCNLocale.value ? 'contact_us_cn' : 'contact_us' }.json`)
        footer = content('./globals/footer.json')
        agileTeams = content('./globals/agile_teams.json')

    } else if (VUE_APP_REGION === Region.HK) {

        home = content('./pages/home.json')
        contactForm = content(`./forms/${ isCNLocale.value ? 'contact_us_cn' : 'contact_us' }.json`)
        footer = content('./globals/footer.json')
        newContact = content('./pages/contact-us.json')
        contact = content('./pages/contact-us.json')

    }

    const serviceData = (slug: string) => {

        return services.find(service => service.slug === slug)

    }
    const caseStudyData = (slug: string) => {

        return caseStudies.find(caseStudy => caseStudy.slug === slug)

    }
    const blogData = (slug: string) => {

        return blogs.find(blog => blog.slug === slug)

    }

    function switchLocale() {

        const route = useRoute()

        if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_LANGUAGE) {

            const { path } = route

            window.location.href = isCNLocale.value ? `https://digitalcreative.cn${ path }` : `https://zh.digitalcreative.cn${ path }`

        } else {

            isCNLocale.value = !isCNLocale.value

        }

    }

    return {
        isCNLocale,
        switchLocale,
        currentLocale: computed(() => isCNLocale.value ? Locale.CHINESE : Locale.ENGLISH),

        /**
         * CMS - pages
         */
        home: shallowRef(home),
        blogs: shallowRef(blogs),
        blog: shallowRef(blog),
        openSource: shallowRef(openSource),
        whatWeDo: shallowRef(whatWeDo),
        newContact: shallowRef(newContact),
        contact: shallowRef(contact),
        services: shallowRef(services),
        caseStudies: shallowRef(caseStudies),
        contactForm: shallowRef(contactForm),
        footer: shallowRef(footer),
        agileTeams: shallowRef(agileTeams),
        about: shallowRef(about),
        serviceData,
        caseStudyData,
        blogData,

        // Improve this: English and Chinese content are both loaded for the same site, it's only needed one at a time
        work: computed(() => (isCNLocale.value ? workCn : workEn)),
        menu: computed(() => (isCNLocale.value ? menuCn : menuEn)),
        cursor: computed(() => (isCNLocale.value ? cursorCn : cursorEn)),
        notFound: computed(() => (isCNLocale.value ? notFoundCn : notFoundEn))
    }

}
