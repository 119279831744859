

    import { defineComponent, PropType, ref, watch } from 'vue'
    import { Rules } from '../../compositions/form/rules/Rules'
    import { InputType } from '../../enums/InputType'
    import Error from './Error.vue'

    export default defineComponent({
        name: 'Input',
        components: { Error },
        props: {
            default: { type: String, default: null },
            placeholder: { type: String, default: '' },
            name: { type: String, required: true },
            help: { type: String, default: null },
            type: { type: String as PropType<InputType>, default: InputType.Text },
            validation: { type: Object as PropType<Rules>, required: true },
            extraPadding: { type: Boolean, default: false }
        },
        setup(props) {

            const fieldType = ref(props.type)
            const value = ref(props.default)

            watch(value, value => props.validation.update(value))

            return {
                value,
                hasErrors: props.validation.hasErrors,
                errorMessages: props.validation.errorMessages,
                fieldType,
                InputType
            }

        }
    })

