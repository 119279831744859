

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Container',
        props: {
            fluid: {
                type: Boolean, default: false
            }
        }
    })

