

    import { computed, defineComponent } from 'vue'
    import Anchor from './Anchor.vue'
    import Container from './Container.vue'
    import ContactHeader from './ContactHeader.vue'
    import SocialMediaButton from './SocialMediaButton.vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Paragraph from './Paragraph.vue'
    import Button from './Button.vue'
    import { useCms } from '../compositions/UseCms'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useRoute } from 'vue-router'
    import Heading from './Heading.vue'
    import EmailSubscription from './EmailSubscription.vue'
    import { useRegion } from '../compositions/UseRegion'
    import { Region } from '../enums/Region'

    export default defineComponent({
        name: 'Footer',
        components: {
            ContactHeader,
            Container,
            Anchor,
            Paragraph,
            Grid,
            Column,
            SocialMediaButton,
            Heading,
            Button,
            EmailSubscription
        },
        setup() {

            const route = useRoute()
            const { footer, services, caseStudies } = useCms()
            const { region } = useRegion()

            return {
                Route,
                Theme,
                caseStudies,
                services,
                region,
                Region,
                cms: footer,
                isContactPage: computed(() => route.name === Route.Contact),
                socialMedia: [
                    {
                        name: 'LinkedIn',
                        link: 'https://linkedin.com/company/digital-creative-asia/'
                    },
                    {
                        name: 'Instagram',
                        link: 'https://instagram.com/digitalcreative.cn'
                    }
                ]
            }

        }
    })

