import { Ref, shallowRef } from 'vue'
import { Rule } from './rules/Rule'
import { Rules, RulesOptions } from './rules/Rules'
import { ValidationManager } from './rules/ValidationManager'

interface UseValidationReturnInterface<T extends string> {
    validation: Ref<Record<T, Rules>>,
    validate: () => boolean,

    getData(): Record<T, string | number | null>,
}

export function useValidation<T extends string>(rules: Record<T, Rule[]>, options: Partial<RulesOptions> = {}): UseValidationReturnInterface<T> {

    const fields: Record<T, Rules> = {} as never
    const manager = new ValidationManager(options)

    for (const property in rules) {

        fields[property] = manager.createRulePack(property, rules[property])

    }

    return {
        validation: shallowRef(fields),
        validate: (): boolean => manager.validate(),
        getData() {

            return manager.getData()

        }
    }

}
