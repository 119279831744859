import { err as error, ok, Result } from 'neverthrow'
import { readonly } from 'vue'
import { ServerError, SuccessInterface } from '../Interfaces/ApiInterface'
import { ContentMarketerInterface, JobEnquiryInterface } from '../Interfaces/EnquiryInterface'
import { trackConversion } from '../utils'

function getHeaders(isMultipart = false): Record<string, string> {

    const headers: Record<string, string> = {
        accept: 'application/json'
    }

    if (!isMultipart) {

        headers['content-type'] = 'application/json'

    }

    return headers

}

async function handleResponse<Success extends SuccessInterface, Error>(response: Response): Promise<Result<Success['data'], Error>> {

    const responseBody = await response.json()

    if (response.ok) {

        return ok(readonly(responseBody))

    }

    return error(responseBody)

}

async function get<Success extends SuccessInterface, Error>(url: string): Promise<Result<Success['data'], Error>> {

    const response = await fetch(url, { headers: getHeaders() })

    return handleResponse<Success, Error>(response)

}

async function post<Success extends SuccessInterface, Error>(url: string, body: BodyInit | null = null, isMultipart = false): Promise<Result<Success['data'], Error>> {

    const response = await fetch(url, {
        method: 'POST',
        body,
        headers: getHeaders(isMultipart)
    })

    return handleResponse<Success, Error>(response)

}

export function useApi() {

    return {
        contentMarketer(data: ContentMarketerInterface) {

            const formData = new FormData()

            for (const key in data) {

                if (key === 'resume') {

                    const file = data[key as keyof ContentMarketerInterface] as File

                    formData.append(key, file, file.name)

                } else {

                    formData.append(key, data[key as keyof ContentMarketerInterface] as string)

                }

            }

            return post<SuccessInterface, ServerError>('https://api.digitalcreative.cn/content-marketer', formData, true)

        },
        jobEnquiry(data: JobEnquiryInterface) {

            const formData = new FormData()

            for (const key in data) {

                if (key === 'resume') {

                    const file = data[key as keyof JobEnquiryInterface] as File

                    formData.append(key, file, file.name)

                } else {

                    formData.append(key, data[key as keyof JobEnquiryInterface] as string)

                }

            }

            return post<SuccessInterface, ServerError>('https://api.digitalcreative.cn/job-contact', formData, true)

        },
        formPost(data: any, endpoint: string) {

            const formData = new FormData()

            for (const key in data) {

                if (data[key] instanceof File) {

                    const file = data[key] as File

                    formData.append(key, file, file.name)

                } else {

                    formData.append(key, data[key] as string)

                }

            }

            trackConversion('AW-10982979937/nQHuCKXald8DEOHyi_Uo')

            return post<SuccessInterface, ServerError>(endpoint, formData, true)

        },
        openSourceMetrics(locale = 'en') {

            return get<any, ServerError>(`https://statamic.digitalcreative.cn/frontend-api/collections/open_sources/entries?sort=-downloads&filter[site]=${ locale }`)

        },
        openSourceCategories(locale = 'en') {

            return get<any, ServerError>(`https://statamic.digitalcreative.cn/frontend-api/taxonomies/open_source_category/terms?filter[site]=${ locale }`)

        },
        openSourcePackage(id: string, locale = 'en') {

            return get<any, ServerError>(`https://statamic.digitalcreative.cn/frontend-api/collections/open_sources/entries/${ id }?filter[locale]=${ locale }`)

        },
        subscribeNewsletter(email: string) {

            const formData = new FormData()

            formData.append('email', email)

            return post<SuccessInterface, ServerError>('https://api.digitalcreative.cn/subscribe', formData, true)

        }
    }

}
