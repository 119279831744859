

    import { defineComponent, PropType } from 'vue'

    export default defineComponent({
        name: 'CheckboxInput',
        props: {
            title: { type: String as PropType<string>, required: true },
            checked: { type: Boolean as PropType<boolean>, default: false }
        },
        emits: [ 'update:checked' ],
        setup(props, { emit }) {

            function toggle() {

                emit('update:checked', !props.checked)

            }

            return {
                toggle
            }

        }
    })

