

    import { defineComponent, ref, watch } from 'vue'
    import Grid from './Grid/Grid.vue'
    import Column from './Grid/Column.vue'
    import Heading from './Heading.vue'
    import Paragraph from './Paragraph.vue'
    import { useCms } from '../compositions/UseCms'
    import Input from './Form/Input.vue'
    import { email, required } from '../compositions/form/UseValidators'
    import { useValidation } from '../compositions/form/UseValidation'
    import { InputType } from '../enums/InputType'
    import Button from './Button.vue'
    import { useApi } from '../compositions/UseApi'
    import CheckboxInput from './Form/CheckboxInput.vue'
    import Anchor from './Anchor.vue'
    import { Route } from '../enums/Route'
    import { isCNLocale } from '../compositions/useI18n'

    export default defineComponent({
        name: 'EmailSubscription',
        components: {
            Grid,
            Column,
            Heading,
            Paragraph,
            Input,
            Button,
            CheckboxInput,
            Anchor
        },
        setup() {

            const formLoading = ref(false)
            const checked = ref(false)
            const isCheckBoxErrorShown = ref(false)
            const isSubscriptionSuccess = ref(false)

            watch(checked, (value) => {

                if (value) isCheckBoxErrorShown.value = false

            })

            const { validation, validate, getData } = useValidation({
                email: [
                    isCNLocale.value ? required('请填写邮箱地址') : required('Email is required'),
                    isCNLocale.value ? email('请提供一个正确的邮箱地址') : email('Not a valid email format')
                ]
            })

            async function submitForm() {

                if (!validate() || formLoading.value === true) {

                    return

                }

                if (!checked.value) {

                    isCheckBoxErrorShown.value = true

                    return

                }

                formLoading.value = true

                const data = getData()

                if (data.email) {

                    try {

                        const response = await useApi().subscribeNewsletter(data.email as string)

                        if (response?.isOk()) {

                            isSubscriptionSuccess.value = true

                        }

                    } catch (error) {

                        console.error(error)

                    }

                    formLoading.value = false

                }

            }

            return {
                validation,
                submitForm,
                isCNLocale,
                formLoading,
                InputType,
                checked,
                isCheckBoxErrorShown,
                isSubscriptionSuccess,
                Route
            }

        }
    })

