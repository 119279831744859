import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "anchor__arrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinkArrow = _resolveComponent("LinkArrow")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component.is), _mergeProps({
    class: ["anchor", {
            '--underlined': _ctx.underlined,
            'font-bold': _ctx.bold,
            '--arrow': _ctx.showArrow,
            '--smaller': _ctx.smaller,
            '--newStyle': _ctx.newStyle
        }]
  }, _ctx.component.params, {
    onClick: _ctx.onClick,
    onCopy: _ctx.onCopy
  }), {
    default: _withCtx(() => [
      (_ctx.underlined)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass([ { 'ml-3 leading-normal w-full': _ctx.newStyle } ])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _renderSlot(_ctx.$slots, "default", { key: 1 }),
      (_ctx.showArrow)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_LinkArrow)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class", "onClick", "onCopy"]))
}