<template>
    <svg width="20"
         height="10"
         viewBox="0 0 20 10"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M5.24996 5.46007V3.59007H7.17996V5.46007H5.24996ZM1.78996 5.46007V3.59007H3.70996V5.46007H1.78996ZM5.24996 2.16007V0.450068H3.70996V2.16007H0.329956V7.41007H1.78996V6.89007H3.70996V9.95007H5.24996V6.89007H7.17996V7.36007H8.70996V2.16007H5.24996Z" fill="currentColor"/>
        <path d="M15.99 3.51007C15.65 4.47007 15.18 5.28007 14.59 5.96007C13.92 5.25007 13.39 4.43007 13 3.51007H15.99ZM19.14 3.51007V2.09007H14.94L15.75 1.84007C15.65 1.42007 15.37 0.790068 15.12 0.320068L13.56 0.780068C13.75 1.18007 13.93 1.69007 14.03 2.09007H9.90996V3.51007H11.49C12.01 4.86007 12.65 6.01007 13.48 6.97007C12.47 7.71007 11.22 8.23007 9.68996 8.57007C9.96996 8.91007 10.41 9.59007 10.57 9.94007C12.15 9.50007 13.47 8.87007 14.56 8.01007C15.59 8.84007 16.84 9.46007 18.38 9.86007C18.6 9.46007 19.03 8.82007 19.36 8.50007C17.91 8.18007 16.7 7.65007 15.7 6.93007C16.52 6.00007 17.16 4.87007 17.63 3.51007H19.14Z" fill="currentColor"/>
    </svg>
</template>
