import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "social-media__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Anchor = _resolveComponent("Anchor")!

  return (_openBlock(), _createBlock(_component_Anchor, {
    route: $props.link,
    external: "",
    "aria-label": `Social Media Link ${$props.name}`
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["social-media", [ `--${ $props.name }`, { '--large': $props.large } ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($props.name)))
        ])
      ], 2)
    ]),
    _: 1
  }, 8, ["route", "aria-label"]))
}