

    import { defineComponent } from 'vue'

    export default defineComponent({
        name: 'Paragraph',
        props: {
            tag: { type: String, default: 'p' },
            heading: { type: Boolean, default: false },
            noMargin: { type: Boolean, default: false },
            innerSpace: { type: Boolean, default: true },
            gray: { type: Boolean, default: false },
            smaller: { type: Boolean, default: false }
        }
    })

