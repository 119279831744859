

    import { defineComponent, PropType, provide } from 'vue'
    import { useResponsiveValue } from '../../compositions/UseResponsiveValue'
    import Container from '../Container.vue'

    export default defineComponent({
        name: 'Grid',
        components: { Container },
        props: {
            columns: { type: [ Number, Array ] as PropType<number | number[]>, default: () => [ 2, 6 ] },
            collapsed: { type: Boolean, default: false },
            centered: { type: Boolean, default: false },
            fluid: { type: Boolean, default: false },
            fluidOnMobile: { type: Boolean, default: false },
            reverseOnMobile: { type: Boolean, default: false }
        },
        setup(props) {

            const responsiveColumns = useResponsiveValue(props.columns)

            provide('columns', responsiveColumns)

            return {
                responsiveColumns
            }

        }
    })

