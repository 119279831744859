
    import { defineComponent, PropType } from 'vue'
    import { RouteLocation, useRouter } from 'vue-router'
    import Spinner from './Spinner.vue'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Locale } from '../enums/Locale'

    export default defineComponent({
        name: 'Button',
        components: {
            Spinner
        },
        props: {
            large: { type: Boolean, default: false },
            purple: { type: Boolean, default: false },
            purpleSolid: { type: Boolean, default: false },
            pinkSolid: { type: Boolean, default: false },
            black: { type: Boolean, default: false },
            isLoading: { type: Boolean, default: false },
            noSmallPadding: { type: Boolean, default: false },
            route: {
                type: [ Object, String ] as PropType<RouteLocation | string>,
                required: false
            },
            url: {
                type: String,
                default: ''
            }
        },
        setup(props) {

            const { isCNLocale } = useTranslation()
            const router = useRouter()

            return {
                goToRoute() {

                    if (props.route) {

                        const name = props.route.name
                        const parameters = props.route.params
                        const locale = isCNLocale ? Locale.CHINESE : ''
                        const routeName = name || props.route

                        router.push({ name: routeName, params: { locale, ...parameters } })

                    } else if (props.url) {

                        router.push(props.url)

                    }

                }
            }

        }
    })
