import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "relative z-10 flex menu__dc" }
const _hoisted_2 = { class: "flex flex-col flex-1 w-full mt-16 mb-10 overflow-auto lg:mt-0 lg:mb-0 lg:overflow-visible" }
const _hoisted_3 = { class: "flex flex-col items-center justify-center flex-1 w-full sm:space-y-3 lg:flex-row lg:justify-end" }
const _hoisted_4 = {
  key: 0,
  class: "menu__dropdown__content"
}
const _hoisted_5 = { class: "flex items-center justify-center menu__locale__inner h-11 w-11" }
const _hoisted_6 = {
  key: 1,
  class: "text-sm font-body bold"
}
const _hoisted_7 = { class: "flex items-center justify-center flex-none w-full pb-10 space-x-5 lg:hidden" }
const _hoisted_8 = { class: "relative z-10 flex items-center justify-end space-x-3 cursor-pointer select-none lg:hidden" }
const _hoisted_9 = {
  key: 1,
  class: "text-sm bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DC = _resolveComponent("DC")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_Chinese = _resolveComponent("Chinese")!
  const _component_SocialMediaButton = _resolveComponent("SocialMediaButton")!
  const _component_Hamburger = _resolveComponent("Hamburger")!

  return (_openBlock(), _createElementBlock("nav", {
    ref: "menuRef",
    role: "navigation",
    class: _normalizeClass(["flex items-center justify-between pr-4 menu lg:px-0", [ _ctx.modeClass, { '--show-menu': _ctx.show, '--hidden': _ctx.isMenuHidden }, _ctx.menuClasses ]]),
    style: _normalizeStyle(_ctx.menuStyle)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_RouterLink, {
        to: { name: _ctx.Route.Home },
        "aria-label": "Home"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_DC, { class: "menu__dc" })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["items-center hidden h-full pr-6 menu__items lg:flex lg:justify-end", { '--show': _ctx.show }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.items, (item, key) => {
            return (_openBlock(), _createElementBlock("li", {
              key: key,
              class: _normalizeClass(["menu__items__link", {
                          '--contact': item.route.name == _ctx.Route.Contact,
                          '--zh': item.route.name == _ctx.Route.Contact && _ctx.isCNLocale,
                          'lg:hidden': item.route.name === _ctx.Route.Home,
                          'menu__dropdown': item.route.name === _ctx.Route.WhatWeDo,
                      }])
            }, [
              _createVNode(_component_RouterLink, {
                to: { name: item.route.name }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1032, ["to"]),
              (item.route.name === _ctx.Route.WhatWeDo)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: index,
                        class: "menu__dropdown__item"
                      }, [
                        _createVNode(_component_RouterLink, {
                          to: { name: _ctx.Route.Services, params: { slug: service.slug } }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(service.title), 1)
                          ]),
                          _: 2
                        }, 1032, ["to"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          }), 128)),
          (_ctx.region === _ctx.Region.CN)
            ? (_openBlock(), _createElementBlock("li", {
                key: 0,
                class: "menu__locale",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.switchLocale && _ctx.switchLocale(...args)))
              }, [
                _createElementVNode("div", _hoisted_5, [
                  (!_ctx.isCNLocale)
                    ? (_openBlock(), _createBlock(_component_Chinese, { key: 0 }))
                    : (_openBlock(), _createElementBlock("div", _hoisted_6, " ENG "))
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialMedia, (social, index) => {
          return (_openBlock(), _createBlock(_component_SocialMediaButton, _mergeProps({ key: index }, social), null, 16))
        }), 128))
      ])
    ], 2),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.region === _ctx.Region.CN)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "menu__locale --small",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.switchLocale && _ctx.switchLocale(...args)))
          }, [
            (!_ctx.isCNLocale)
              ? (_openBlock(), _createBlock(_component_Chinese, { key: 0 }))
              : (_openBlock(), _createElementBlock("div", _hoisted_9, " ENG "))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "menu__hamburger",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggle(!_ctx.show)))
      }, [
        _createVNode(_component_Hamburger, { "is-open": _ctx.show }, null, 8, ["is-open"])
      ])
    ])
  ], 6))
}