

    import { defineComponent, PropType } from 'vue'
    import { RouteLocation } from 'vue-router'
    import Grid from './Grid/Grid.vue'
    import Column from './Grid/Column.vue'
    import Button from './Button.vue'
    import Heading from './Heading.vue'

    export default defineComponent({
        name: 'ContactHeader',
        props: {
            heading: { type: String, required: true },
            route: { type: [ Object, String ] as PropType<RouteLocation | string>, required: false },
            label: { type: String, required: true }
        },
        components: {
            Grid,
            Column,
            Button,
            Heading
        }
    })

