import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center justify-center h-full" }
const _hoisted_2 = {
  key: 0,
  class: "ml-2"
}
const _hoisted_3 = {
  key: 1,
  class: "flex items-center justify-center h-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", [
      { '--purple': _ctx.purple },
      { '--purple-solid': _ctx.purpleSolid },
      { '--black': _ctx.black },
      { '--pink-solid': _ctx.pinkSolid },
      { '--large': _ctx.large },
      { '--no-small-padding': _ctx.noSmallPadding },
    ]]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToRoute && _ctx.goToRoute(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_Spinner)
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default")
          ]))
    ])
  ], 2))
}