

    import { computed, defineComponent, PropType } from 'vue'
    import { RouteLocation, useRoute } from 'vue-router'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Locale } from '../enums/Locale'
    import { trackConversion } from '../utils'
    import { Route } from '../enums/Route'
    import LinkArrow from './Logos/LinkArrow.vue'

    export default defineComponent({
        name: 'Anchor',
        components: {
            LinkArrow
        },
        props: {
            route: { type: [ Object, String ] as PropType<RouteLocation | string>, required: true },
            underlined: { type: Boolean, default: false },
            mailTo: { type: Boolean, default: false },
            telephone: { type: Boolean, default: false },
            external: { type: Boolean, default: false },
            showArrow: { type: Boolean, default: false },
            bold: { type: Boolean, default: true },
            smaller: { type: Boolean, default: false },
            purple: { type: Boolean, default: false },
            newStyle: { type: Boolean, default: false },
            disabled: { type: Boolean, default: false }
        },
        setup(props) {

            const { isCNLocale } = useTranslation()
            const route = useRoute()

            const routeParameters = computed(() => {

                const name = props.route.name
                const parameters = props.route.params
                const locale = isCNLocale ? Locale.CHINESE : ''
                const routeName = name || props.route

                return {
                    name: routeName,
                    params: { locale, ...parameters }
                }

            })

            const externalHref = computed(() => {

                if (props.mailTo) {

                    return `mailto:${ props.route }`

                }

                if (props.telephone) {

                    return `tel:${ props.route }`

                }

                return props.route

            })

            return {
                onCopy() {

                    if (props.mailTo) {

                        if (route.name === Route.Contact) {

                            trackConversion('AW-10982979937/-KI7CKvald8DEOHyi_Uo')

                        } else {

                            trackConversion('AW-10982979937/yzy3CKjald8DEOHyi_Uo')

                        }

                    }

                },
                onClick() {

                    if (props.mailTo) {

                        if (route.name === Route.Contact) {

                            trackConversion('AW-10982979937/73CKCLHald8DEOHyi_Uo')

                        } else {

                            trackConversion('AW-10982979937/y0_yCK7ald8DEOHyi_Uo')

                        }

                    }

                    if (props.telephone) {

                        trackConversion('AW-10982979937/ZGmVCMCWlt8DEOHyi_Uo')

                    }

                },
                component: computed(() => {

                    if (props.disabled) {

                        return {
                            is: 'div'
                        }

                    } else if (props.mailTo || props.telephone || props.external) {

                        return {
                            is: 'a',
                            params: {
                                href: externalHref.value,
                                target: '_blank',
                                rel: 'noopener'
                            }
                        }

                    } else {

                        return {
                            is: 'router-link',
                            params: {
                                to: routeParameters.value
                            }
                        }

                    }

                })
            }

        }
    })

