import './scss/main.scss'
import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import AOS from '@dcasia/vue-aos'

createApp(App)
    .use(router)
    .use(AOS, {
        // disable: window.__HEADLESS,
        disable: true,
        isBrowser: !window.__HEADLESS,
        anchorPlacement: 'center-bottom',
        once: true,
        duration: 1000,
        easing: 'ease-out-custom',
        offset: 0,
        startEvent: 'init-aos',

        /**
         * Disable this sweet function as our website has complex transition logic flow which can affect DOM element position
         * so it makes more sense to manually control aos element refreshing or recollecting
         *
         */
        disableMutationObserver: true
    })
    .mount('#app', true)
