

    import { defineComponent, onUnmounted } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import { useGrid } from '../compositions/UseGrid'

    export default defineComponent({
        name: 'Debugger',
        components: { Grid, Column },
        setup() {

            const { toggleGrid, showGrid } = useGrid()

            const toggleDebugger = (event: KeyboardEvent) => {

                const target = event.target

                if (target instanceof HTMLInputElement || (target instanceof HTMLDivElement && target.contentEditable === 'true')) {

                    return

                }

                /**
                 * Only toggle debugger when control is held up
                 */
                if (event.ctrlKey === false) {

                    return

                }

                if (event.key === 'g') {

                    toggleGrid()

                } else if (event.key === 'i') {

                    document.body.classList.toggle('pesticide')

                } else if (event.key === 'x') {

                    document.body.classList.toggle('x-ray')

                }

            }

            window.addEventListener('keydown', toggleDebugger)
            onUnmounted(() => window.removeEventListener('keydown', toggleDebugger))

            return {
                showGrid,
                maxHeight: window.innerHeight
            }

        }

    })

