

    import { computed, ComputedRef, defineComponent, inject, PropType, provide } from 'vue'
    import { ResponsiveValue, useResponsiveValue } from '../../compositions/UseResponsiveValue'

    export default defineComponent({
        name: 'Column',
        props: {
            span: { type: [ Number, Array ] as PropType<ResponsiveValue<number>>, default: null },
            push: { type: [ Number, Array ] as PropType<ResponsiveValue<number>>, default: null },
            row: { type: Boolean, default: false },
            container: { type: Boolean, default: false },
            center: { type: Boolean, default: false }
        },
        setup(props) {

            const columns = inject<ComputedRef<number>>('columns')!
            const responsiveSpan = useResponsiveValue(props.span)

            provide('columns', responsiveSpan)

            /**
             * This line will cause warning because there is no 'push' provided in the upstream
             */
            // Const push = inject<ComputedRef<number>>('push')
            const responsivePush = useResponsiveValue(props.push)

            provide('push', responsivePush)

            return {
                classes: computed(() => {

                    const classes = []

                    if (responsiveSpan.value === columns.value || responsiveSpan.value === null) {

                        classes.push('w-full')

                    } else {

                        classes.push(`w-${ responsiveSpan.value }/${ columns.value }`)

                    }

                    if (props.push) {

                        /*
                         * Console.log(
                         *     `push ${ props.push }`,
                         *     `responsive ${ responsiveSpan.value }`,
                         *     `columns ${ columns.value }`
                         * )
                         */

                        if ((responsivePush.value + responsiveSpan.value) > columns.value) {

                            throw new Error(`Push + Span should not be grater than ${ columns.value }`)

                        }

                        classes.push(`ml-${ responsivePush.value }/${ columns.value }`)

                    }

                    if (props.container) {

                        classes.push('--container')

                    } else {

                        classes.push(props.row ? 'flex-row' : 'flex-col')

                        if (props.center) {

                            classes.push('items-center')

                        }

                    }

                    return classes

                })
            }

        }
    })

