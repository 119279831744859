import { Theme } from './enums/Theme'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfigFile from '../tailwind.config.js'

const colors = resolveConfig(tailwindConfigFile).theme.colors

export const themeColor: Record<Theme, string> = {
    [Theme.Blue]: colors.blue[100],
    [Theme.Dark]: colors.gray[900],
    [Theme.Default]: colors.white,
    [Theme.Green]: colors.teal[200],
    [Theme.LightPink]: colors.FFF2F9,
    [Theme.Pink]: colors.pink[500],
    [Theme.Purple]: colors.purple[200]
}
