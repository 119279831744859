<template>
    <svg width="24"
         height="24"
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <rect x="1"
              y="12.25"
              width="22"
              height="11"
              rx="4"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M4 0.75L7 4.75V9.25"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M10 0.75L7 4.75V9.25"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13 7.75C13 8.57843 12.3284 9.25 11.5 9.25C10.6716 9.25 10 8.57843 10 7.75V4.75C10 3.92157 10.6716 3.25 11.5 3.25C12.3284 3.25 13 3.92157 13 4.75V7.75Z"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M19 3.25V7.25C19 8.35457 18.1046 9.25 17 9.25C15.8954 9.25 15 8.35457 15 7.25V3.25"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M19 3.25V9.25"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M11 17V20.25"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M3.97998 15.25H8.01998"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M6 15.25V20.23"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M13.5 14.25V20.23"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.5 19.25C15.5 19.8023 15.0523 20.25 14.5 20.25C13.9477 20.25 13.5 19.8023 13.5 19.25V18C13.5 17.4477 13.9477 17 14.5 17C15.0523 17 15.5 17.4477 15.5 18V19.25Z"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M9 17V19.25C9 19.8023 9.44772 20.25 10 20.25C10.5523 20.25 11 19.8023 11 19.25V17"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M18.83 16.75C19.3822 16.75 19.83 17.1977 19.83 17.75H17.83"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
        <path d="M19.83 19.86C19.5188 20.2176 19.0189 20.3453 18.5743 20.1809C18.1298 20.0164 17.8334 19.594 17.83 19.12V17.75C17.83 17.1977 18.2777 16.75 18.83 16.75"
              stroke="#EB5757"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"/>
    </svg>
</template>
