import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    fluid: _ctx.fluid || _ctx.fluidOnMobile,
    class: _normalizeClass(["grid", { '--collapsed': _ctx.collapsed }])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["grid__content flex", [
                 _ctx.reverseOnMobile ? 'flex-wrap-reverse md:flex-wrap' : 'flex-wrap',
                 { 'items-center': _ctx.centered },
                 { '--fluid': _ctx.fluid },
                 { '--fluid-on-mobile': _ctx.fluidOnMobile },
             ]])
      }, [
        _renderSlot(_ctx.$slots, "default", { columns: _ctx.responsiveColumns })
      ], 2)
    ]),
    _: 3
  }, 8, ["fluid", "class"]))
}