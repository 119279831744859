import { computed, ComputedRef, Ref, ref } from 'vue'
import { Rule } from './Rule'
import { isBlank } from '../UseValidators'

export interface RulesOptions {
    validateOnType: boolean,
}

export class Rules {

    private readonly rules: Rule[]

    public hasErrors: ComputedRef<boolean>
    public errorMessages: Ref<string[]>
    public valid: Ref<boolean>
    public validated: Ref<boolean>
    public value: string | number | null = null
    public name: string
    public options: RulesOptions = {
        validateOnType: true
    }

    public constructor(name: string, rules: Rule[], options: Partial<RulesOptions> = {}) {

        this.name = name
        this.rules = rules
        this.valid = ref(true)
        this.validated = ref(false)
        this.hasErrors = computed(() => !this.valid.value)
        this.errorMessages = ref([])
        this.options = { ...this.options, ...options }

    }

    private updateErrorMessages(): void {

        if (this.validated.value) {

            this.errorMessages.value = this.rules
                .filter(rule => !rule.state)
                .map(rule => rule.errorMessage)
                .filter(Boolean) as string[]

        }

    }

    public update(value: string) {

        this.value = value

        if (this.options.validateOnType && !isBlank(value)) {

            this.validate()

        } else {

            this.reset()

        }

    }

    public reset() {

        this.validated.value = false
        this.valid.value = true

        while (this.errorMessages.value.length) {

            this.errorMessages.value.pop()

        }

        for (const rule of this.rules) {

            rule.reset()

        }

    }

    public validate(forceValidation = false): boolean {

        if (isBlank(this.value) && !forceValidation) {

            this.validated.value = false
            this.valid.value = true

        } else {

            this.validated.value = true
            this.valid.value = this.rules.map(rule => rule.process(this.value)).every(Boolean)

        }

        this.updateErrorMessages()

        return this.valid.value

    }

}
